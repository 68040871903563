import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithAuth } from '../utils/apiHelper';

const PaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [maxAmount, setMaxAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchPaymentMethods();
    fetchMaxCajaAmount();
  }, []);

  const fetchPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithAuth('/administracion/metodos/pago', {
        method: 'GET',
      });
      setPaymentMethods(data);
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      toast.error('Error al obtener los métodos de pago.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMaxCajaAmount = async () => {
    try {
      const data = await fetchWithAuth('/caja/max/amount', {
        method: 'GET',
      });
      setMaxAmount(data.max_amount);
    } catch (error) {
      console.error('Error fetching max caja amount:', error);
      toast.error('Error al obtener el monto máximo de caja.');
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedMethods = [...paymentMethods];
    updatedMethods[index][field] = value;
    setPaymentMethods(updatedMethods);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await fetchWithAuth('/administracion/metodos/pago', {
        method: 'PUT',
        body: JSON.stringify({ metodos: paymentMethods }),
      });
      toast.success('Métodos de pago actualizados exitosamente.');
    } catch (error) {
      console.error('Error updating payment methods:', error);
      toast.error('Error al actualizar los métodos de pago.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleMaxAmountChange = (e) => {
    setMaxAmount(e.target.value);
  };

  const handleSaveMaxAmount = async () => {
    setIsLoading(true);
    try {
      await fetchWithAuth('/caja/max/amount', {
        method: 'PUT',
        body: JSON.stringify({ max_amount: parseInt(maxAmount, 10) }),
      });
      toast.success('Monto máximo de caja actualizado exitosamente.');
    } catch (error) {
      console.error('Error updating max caja amount:', error);
      toast.error('Error al actualizar el monto máximo de caja.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Gestión de Métodos de Pago</h1>
      {isLoading && (
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Nombre</th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Tiene Recargo</th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Valor Recargo</th>
            </tr>
          </thead>
          <tbody>
            {paymentMethods.map((method, index) => (
              <tr key={method.metodo_id}>
                <td className="px-6 py-4 border-b border-gray-200">
                  <h2 className="w-full p-2 border rounded">{method.nombre}</h2>
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <select
                    value={method.tiene_recargo}
                    onChange={(e) => handleInputChange(index, 'tiene_recargo', e.target.value === 'true')}
                    className="w-full p-2 border rounded"
                  >
                    <option value="true">Sí</option>
                    <option value="false">No</option>
                  </select>
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <input
                    type="number"
                    value={method.valor_recargo}
                    onChange={(e) => handleInputChange(index, 'valor_recargo', parseInt(e.target.value, 10))}
                    className="w-full p-2 border rounded"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 flex justify-end">
        <button
          onClick={handleSave}
          className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
          disabled={isLoading}
        >
          {isLoading ? 'Guardando...' : 'Guardar Cambios'}
        </button>
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-bold mb-4">Monto Máximo de Caja</h2>
        <input
          type="number"
          value={maxAmount}
          onChange={handleMaxAmountChange}
          className="w-full p-2 border rounded mb-4"
        />
        <button
          onClick={handleSaveMaxAmount}
          className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-green-700 transition duration-200"
          disabled={isLoading}
        >
          {isLoading ? 'Guardando...' : 'Guardar Monto Máximo'}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentMethods;
