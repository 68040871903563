import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper'; // Importa la función fetchWithAuth
import { toast, ToastContainer } from 'react-toastify';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [sucursalSearch, setSucursalSearch] = useState('');
  const [sucursales, setSucursales] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState('');

  useEffect(() => {
    // Función para obtener las sucursales desde la API
    const fetchSucursales = async () => {
      try {
        const data = await fetchWithAuth('/auth/sucursales');
        setSucursales(data); // Almacenar las sucursales en el estado
      } catch (error) {
        console.error('Error al obtener las sucursales:', error);
      }
    };

    fetchSucursales(); // Llamar a la función para obtener las sucursales al cargar el componente
  }, []);

  const handleSucursalSearch = (e) => {
    const searchValue = e.target.value;
    setSucursalSearch(searchValue);

    const matchingSucursal = sucursales.find(
      (sucursal) => sucursal.nombre.toLowerCase() === searchValue.toLowerCase()
    );

    if (matchingSucursal) {
      setSelectedSucursal(matchingSucursal.sucursal_id);
      toast.success(`Sucursal ${matchingSucursal.nombre} seleccionada`);
    } else {
      setSelectedSucursal('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedSucursal) {
      toast.error('Debe seleccionar una sucursal válida.');
      return;
    }

    try {
      await fetchWithAuth('/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password, sucursal_id: selectedSucursal })
      });

      toast.success('Usuario Creado');
      // Reset fields after successful submission
      setUsername('');
      setPassword('');
      setSucursalSearch('');
      setSelectedSucursal('');
    } catch (error) {
      console.error('Error al registrar el usuario:', error);
      toast.error('Error al crear Usuario');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">Username</label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="sucursal" className="sr-only">Sucursal</label>
              <input
                id="sucursal"
                name="sucursal"
                type="text"
                autoComplete="off"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Buscar Sucursal"
                value={sucursalSearch}
                onChange={handleSucursalSearch}
              />
              {selectedSucursal && (
                <p className="mt-2 text-sm text-green-600">
                  Sucursal seleccionada: {sucursales.find(s => s.sucursal_id === selectedSucursal)?.nombre}
                </p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Crear Usuario
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
