import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchWithAuth } from '../utils/apiHelper';
import { toast } from 'react-toastify';

function Clientes() {
  const [clientes, setClientes] = useState([]);
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [telefono, setTelefono] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);

  useEffect(() => {
    fetchClientes();
  }, []);

  useEffect(() => {
    setFilteredClients(
      clientes.filter(cliente =>
        cliente.nombre.toLowerCase().includes(searchQuery.toLowerCase()) ||
        cliente.apellido.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, clientes]);

  const fetchClientes = async () => {
    try {
      const data = await fetchWithAuth('/clientes/');
      setClientes(data);
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
    }
  };

  const handleAddCliente = async (e) => {
    e.preventDefault();
    const newCliente = { nombre, apellido, telefono, telefono_sufijo: '549' };
    try {
      await fetchWithAuth('/clientes/create', {
        method: 'POST',
        body: JSON.stringify(newCliente)
      });
      fetchClientes();
      toast.success('Cliente Agregado');
      resetForm();
    } catch (error) {
      toast.error('Error al cargar cliente');
      console.error('Error al añadir el cliente:', error);
    }
  };

  const handleUpdateCliente = async (e) => {
    e.preventDefault();
    const updatedCliente = { ...selectedClient, nombre, apellido, telefono: `${telefono}`, telefono_sufijo: '+549' };
    try {
      await fetchWithAuth(`/clientes/${selectedClient.cliente_id}`, {
        method: 'PUT',
        body: JSON.stringify(updatedCliente)
      });
      fetchClientes();
      toast.success('Cliente Actualizado');
      resetForm();
    } catch (error) {
      toast.error('Error al actualizar cliente');
      console.error('Error al actualizar el cliente:', error);
    }
  };

  const handleDeleteCliente = async (cliente_id) => {
    try {
      await fetchWithAuth(`/clientes/${cliente_id}`, {
        method: 'DELETE'
      });
      setClientes(clientes.filter(c => c.cliente_id !== cliente_id));
      toast.success('Cliente Eliminado');
    } catch (error) {
      toast.error('Error al eliminar cliente');
      console.error('Error al eliminar el cliente:', error);
    }
  };

  const handleSelectClient = (cliente) => {
    setSelectedClient(cliente);
    setNombre(cliente.nombre);
    setApellido(cliente.apellido);
    setTelefono(cliente.telefono.replace('+549', ''));
    setShowForm(true);
  };

  const resetForm = () => {
    setSelectedClient(null);
    setNombre('');
    setApellido('');
    setTelefono('');
    setShowForm(false);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Gestión de Clientes</h1>
      <div className="mb-6 flex justify-between items-center">
        <button
          onClick={() => {
            setShowForm(!showForm);
            if (showForm) {
              resetForm();
            }
          }}
          className="px-6 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150"
        >
          {showForm ? 'Cancelar' : 'Crear Nuevo Cliente'}
        </button>
        <input
          type="text"
          placeholder="Buscar clientes por nombre o apellido..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="mt-1 p-2 w-1/2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      {showForm && (
        <form onSubmit={selectedClient ? handleUpdateCliente : handleAddCliente} className="mb-6 p-6 bg-white rounded-lg shadow-lg space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Nombre</label>
              <input
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Apellido</label>
              <input
                type="text"
                value={apellido}
                onChange={(e) => setApellido(e.target.value)}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">Teléfono</label>
              <div className="flex items-center">
                <span className="px-3 py-2 border border-r-0 border-gray-300 bg-gray-100 rounded-l-md text-gray-600">+549</span>
                <input
                  type="text"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-r-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-full py-2 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150"
          >
            {selectedClient ? 'Actualizar Cliente' : 'Añadir Cliente'}
          </button>
        </form>
      )}
      <div>
        <h2 className="text-xl font-bold mb-4 text-gray-800">Lista de Clientes</h2>
        {filteredClients.length > 0 ? (
          <ul className="space-y-4">
            {filteredClients.map((cliente) => (
              <li key={cliente.cliente_id} className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition duration-150">
                <div className="flex justify-between items-center">
                  <Link
                    to={`/detalles/cliente/${cliente.cliente_id}`}
                    className="font-medium text-indigo-600 hover:text-indigo-900"
                  >
                    {cliente.nombre} {cliente.apellido}
                  </Link>
                  <span className="text-gray-600">{cliente.telefono}</span>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleSelectClient(cliente)}
                      className="px-4 py-2 bg-yellow-500 text-white rounded-md shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 transition duration-150"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => handleDeleteCliente(cliente.cliente_id)}
                      className="px-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-150"
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No hay clientes disponibles.</p>
        )}
      </div>
    </div>
  );
}

export default Clientes;
