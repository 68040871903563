import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { fetchWithAuth } from '../utils/apiHelper';

const SinglePaymentModal = ({ isOpen, onClose, updateAppointments }) => {
  const [paymentMethod, setPaymentMethod] = useState('Efectivo');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedServicesByEmployee, setSelectedServicesByEmployee] = useState({});
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [clientes, setClientes] = useState([]); // New state for clients
  const [selectedClient, setSelectedClient] = useState(null); // New state for selected client

  useEffect(() => {
    const fetchData = async () => {
      await fetchServices();
      await fetchPaymentMethods();
      await fetchEmpleados();
      await fetchClientes(); // Fetch clients when component mounts
    };

    fetchData();
  }, []);

  const fetchServices = async () => {
    try {
      const data = await fetchWithAuth('/services/');
      setServices(data);
    } catch (error) {
      console.error('Error fetching services:', error);
      toast.error('Error al obtener los servicios.');
    }
  };

  const fetchPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithAuth('/administracion/metodos/pago', {
        method: 'GET',
      });
      setPaymentMethods(data);
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      toast.error('Error al obtener los métodos de pago.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEmpleados = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithAuth('/empleados/');
      setEmployees(data);
    } catch (error) {
      console.error('Error al obtener los empleados:', error);
      toast.error('Error al obtener los empleados.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClientes = async () => {
    try {
      const data = await fetchWithAuth('/clientes/');
      setClientes(data);
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
      toast.error('Error al obtener los clientes.');
    }
  };

  const handleEmployeeSelection = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleClientSelection = (client) => {
    setSelectedClient(client);
  };

  const handleServiceSelection = (service) => {
    if (!selectedEmployee) return;

    setSelectedServicesByEmployee(prev => {
      const employeeServices = prev[selectedEmployee.empleado_id] || [];
      const existingService = employeeServices.find(s => s.servicio_id === service.servicio_id);

      if (existingService) {
        return {
          ...prev,
          [selectedEmployee.empleado_id]: employeeServices.map(s =>
            s.servicio_id === service.servicio_id ? { ...s, count: s.count + 1 } : s
          )
        };
      } else {
        return {
          ...prev,
          [selectedEmployee.empleado_id]: [...employeeServices, { ...service, count: 1, empleado_id: selectedEmployee.empleado_id }]
        };
      }
    });
  };

  const handleServiceDeselection = (service) => {
    if (!selectedEmployee) return;

    setSelectedServicesByEmployee(prev => {
      const employeeServices = prev[selectedEmployee.empleado_id] || [];
      const existingService = employeeServices.find(s => s.servicio_id === service.servicio_id);

      if (existingService.count > 1) {
        return {
          ...prev,
          [selectedEmployee.empleado_id]: employeeServices.map(s =>
            s.servicio_id === service.servicio_id ? { ...s, count: s.count - 1 } : s
          )
        };
      } else {
        return {
          ...prev,
          [selectedEmployee.empleado_id]: employeeServices.filter(s => s.servicio_id !== service.servicio_id)
        };
      }
    });
  };

  const handleConfirmPay = async () => {
    const selectedServiceIds = Object.values(selectedServicesByEmployee).flat().flatMap(service => 
      Array(service.count).fill({ servicio_id: service.servicio_id, monto: service.precio, empleado_id: service.empleado_id })
    );

    setLoadingState(true);
    try {
      await fetchWithAuth('/operaciones/crear/pago/general', {
        method: 'POST',
        body: JSON.stringify({ metodo_pago: paymentMethod, cliente_id: selectedClient.cliente_id, servicios: selectedServiceIds }),
      });
      toast.success('Pago realizado exitosamente');
      updateAppointments(); // Refresh appointments after successful payment
      onClose();
    } catch (error) {
      console.error('Error realizando el pago:', error);
      toast.error('Error al realizar el pago: ' + error.message);
    } finally {
      setLoadingState(false);
    }
  };

  const totalAmount = Object.values(selectedServicesByEmployee).flat().reduce((total, service) => {
    return total + (service?.precio || 0) * service.count;
  }, 0);

  const selectedPaymentMethod = paymentMethods.find(method => method.nombre === paymentMethod);
  const surchargeAmount = selectedPaymentMethod && selectedPaymentMethod.tiene_recargo ? (totalAmount * selectedPaymentMethod.valor_recargo) / 100 : 0;
  const totalAmountWithSurcharge = totalAmount + surchargeAmount;

  const filteredServices = services.filter(service =>
    service.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort services to have selected services at the top
  const sortedServices = filteredServices.sort((a, b) => {
    const aSelected = (selectedServicesByEmployee[selectedEmployee?.empleado_id] || []).find(s => s.servicio_id === a.servicio_id);
    const bSelected = (selectedServicesByEmployee[selectedEmployee?.empleado_id] || []).find(s => s.servicio_id === b.servicio_id);
    return (bSelected ? 1 : 0) - (aSelected ? 1 : 0);
  });

  const isConfirmButtonDisabled = !selectedClient || !selectedEmployee || !Object.values(selectedServicesByEmployee).flat().length;

  return (
    <Transition show={isOpen} className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
        </Transition.Child>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <Transition.Child className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full max-h-screen">
          <div className="bg-white p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4 text-center">Realizar Pago</h2>
            
            {/* Client Selection */}
            <div className="mb-4">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Cliente
              </label>
              <select
                className="w-full p-2 border rounded"
                value={selectedClient ? selectedClient.cliente_id : ''}
                onChange={(e) => {
                  const selected = clientes.find(client => client.cliente_id === e.target.value);
                  handleClientSelection(selected);
                }}
              >
                <option value="" disabled>Seleccione un cliente</option>
                {clientes.map((client) => (
                  <option key={client.cliente_id} value={client.cliente_id}>
                    {client.nombre} {client.apellido}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Método de Pago
              </label>
              <select
                className="w-full p-2 border rounded"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                {paymentMethods.map((method) => (
                  <option key={method.metodo_id} value={method.nombre}>
                    {method.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Empleado
              </label>
              <div className="relative">
                <select
                  className="w-full p-2 border rounded appearance-none"
                  value={selectedEmployee ? selectedEmployee.empleado_id : ''}
                  onChange={(e) => {
                    const selected = employees.find(emp => emp.empleado_id === e.target.value);
                    handleEmployeeSelection(selected);
                  }}
                >
                  <option value="" disabled>Seleccione un empleado</option>
                  {employees.map((employee) => (
                    <option key={employee.empleado_id} value={employee.empleado_id}>
                      {employee.nombre}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.292 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Buscar Servicios
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="mb-4 max-h-48 overflow-y-auto">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Selecciona Servicios
              </label>
              {sortedServices.map((service, index) => (
                <div
                  key={service.servicio_id}
                  className={`flex items-center justify-between mb-2 p-2 border rounded ${selectedServicesByEmployee[selectedEmployee?.empleado_id]?.some(s => s.servicio_id === service.servicio_id) ? 'bg-blue-100' : 'bg-gray-100'}`}
                >
                  <div>
                    <span>{service.nombre} - ${service.precio}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    {selectedServicesByEmployee[selectedEmployee?.empleado_id]?.find(s => s.servicio_id === service.servicio_id)?.count > 0 && (
                      <button
                        className="px-2 py-1 bg-red-500 text-white rounded"
                        onClick={() => handleServiceDeselection(service)}
                      >
                        -
                      </button>
                    )}
                    <span>{selectedServicesByEmployee[selectedEmployee?.empleado_id]?.find(s => s.servicio_id === service.servicio_id)?.count || 0}</span>
                    <button
                      className="px-2 py-1 bg-blue-500 text-white rounded"
                      onClick={() => handleServiceSelection(service)}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {Object.keys(selectedServicesByEmployee).map(employeeId => {
              const employee = employees.find(emp => emp.empleado_id === employeeId);
              const services = selectedServicesByEmployee[employeeId] || [];
              return services.length > 0 ? (
                <div key={employeeId} className="mt-4 p-4 bg-gray-100 border border-gray-300 rounded-lg">
                  <h3 className="text-lg font-bold">{employee.nombre}</h3>
                  <ul className="list-disc ml-5 mt-2">
                    {services.map(service => (
                      <li key={service.servicio_id}>
                        {service.nombre} - ${service.precio} x {service.count}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null;
            })}

            <div className="mt-4">
              <h3 className="text-lg font-bold">Total: ${totalAmountWithSurcharge.toFixed(2)}</h3>
              {selectedPaymentMethod && selectedPaymentMethod.tiene_recargo && (
                <p className="text-sm text-gray-500">
                  Incluye un recargo del {selectedPaymentMethod.valor_recargo}% por pago con {selectedPaymentMethod.nombre}
                </p>
              )}
            </div>

            <div className="mt-4 flex justify-end space-x-4">
              <button 
                onClick={onClose} 
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-lg transition-transform transform hover:scale-105"
              >
                Cerrar
              </button>
              <button 
                onClick={handleConfirmPay} 
                className={`bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition-transform transform hover:scale-105 ${isConfirmButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isConfirmButtonDisabled}
              >
                {loadingState ? 'Procesando...' : 'Confirmar Pago'}
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

export default SinglePaymentModal;
