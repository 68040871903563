import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

const ConfirmCancelModal = ({ isOpen, onClose, onConfirm }) => {
  const [motivoCancelacion, setMotivoCancelacion] = useState('');

  const handleConfirm = () => {
    onConfirm(motivoCancelacion);
    setMotivoCancelacion(''); // Reset the input field after confirming
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4">
          <Transition.Child
            as={React.Fragment}
            enter="transition-opacity ease-in duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75"></div>
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
          >
            <div className="relative bg-white rounded-2xl shadow-xl max-w-md w-full p-6 space-y-4">
              <h2 className="text-2xl font-bold text-gray-900 text-center">Confirmar Cancelación</h2>
              <p className="text-center text-gray-700">¿Estás seguro de que deseas cancelar esta cita?</p>
              <textarea
                className="w-full p-2 border rounded mt-2"
                placeholder="Motivo de la cancelación"
                value={motivoCancelacion}
                onChange={(e) => setMotivoCancelacion(e.target.value)}
              />
              <div className="flex justify-center space-x-4 mt-4">
                <button
                  onClick={handleConfirm}
                  className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-200"
                >
                  Sí, cancelar
                </button>
                <button
                  onClick={onClose}
                  className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-200"
                >
                  No, volver
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default ConfirmCancelModal;
