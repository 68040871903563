import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper'; // Import the fetchWithAuth function

import { toast } from 'react-toastify';



function Empleados() {
  const [empleados, setEmpleados] = useState([]);
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading

  useEffect(() => {
    fetchEmpleados();
  }, []);

  useEffect(() => {
    setFilteredEmployees(
      empleados.filter(empleado =>
        `${empleado.nombre.toLowerCase()} ${empleado.apellido.toLowerCase()}`.includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, empleados]);

  const fetchEmpleados = async () => {
    setLoading(true);
    try {
      const data = await fetchWithAuth('/empleados/');
      setEmpleados(data);
    } catch (error) {
      console.error('Error al obtener los empleados:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddEmpleado = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newEmpleado = { nombre, apellido };
    try {
      await fetchWithAuth('/empleados/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newEmpleado)
      });
      fetchEmpleados();
      resetForm();
      toast.success('Creado Con Exito!');
    } catch (error) {
      toast.error('Error');
      console.error('Error al añadir el empleado:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEmpleado = async (empleado_id) => {
    setLoading(true);
    try {
      await fetchWithAuth(`/empleados/borrar/empleado/${empleado_id}`, {
        method: 'DELETE'
      });
      setEmpleados(empleados.filter(e => e.empleado_id !== empleado_id));
      
      toast.success('Eliminado');
      resetForm()
    } catch (error) {
      console.error('Error al eliminar el empleado:', error);
      toast.error('Error al eliminar');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEmpleado = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedEmpleado = { ...selectedEmployee, nombre, apellido };
    try {
      await fetchWithAuth(`/empleados/${selectedEmployee.empleado_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedEmpleado)
      });
      setEmpleados(empleados.map(e => e.empleado_id === updatedEmpleado.empleado_id ? updatedEmpleado : e));
      toast.success('Empleado Actualizado');
      resetForm();
    } catch (error) {
      console.error('Error al actualizar el empleado:', error);
      toast.error('Error al Actualizar');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectEmployee = (empleado) => {
    setSelectedEmployee(empleado);
    setNombre(empleado.nombre);
    setApellido(empleado.apellido);
    setShowForm(true);
  };

  const resetForm = () => {
    setSelectedEmployee(null);
    setNombre('');
    setApellido('');
    setShowForm(false);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Gestión de Empleados</h1>
      <div className="mb-6 flex justify-between items-center">
        <button
          onClick={() => {
            setShowForm(!showForm);
            if (showForm) {
              resetForm();
            }
          }}
          disabled={loading}
          className="px-6 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150"
        >
          {showForm ? 'Cancelar' : 'Crear Nuevo Empleado'}
        </button>
        <input
          type="text"
          placeholder="Buscar empleados por nombre..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="mt-1 p-2 w-1/2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      {showForm && (
        <form onSubmit={selectedEmployee ? handleUpdateEmpleado : handleAddEmpleado} className="mb-6 p-6 bg-white rounded-lg shadow-lg space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Nombre</label>
            <input
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Apellido</label>
            <label className="block text-sm font-medium text-gray-700">Apellido</label>
            <input
              type="text"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150"
          >
            {selectedEmployee ? 'Actualizar Empleado' : 'Añadir Empleado'}
          </button>
        </form>
      )}
      <div>
        <h2 className="text-xl font-bold mb-4">Lista de Empleados</h2>
        {filteredEmployees.length > 0 ? (
          <ul className="space-y-4">
            {filteredEmployees.map((empleado) => (
              <li key={empleado.empleado_id} className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition duration-150">
                <div className="flex justify-between items-center">
                  <div onClick={() => handleSelectEmployee(empleado)} className="cursor-pointer">
                    <span className="font-medium text-gray-800">{empleado.nombre} {empleado.apellido}</span>
                  </div>
                  <button
                    onClick={() => handleDeleteEmpleado(empleado.empleado_id)}
                    disabled={loading}
                    className="ml-4 px-4 py-2 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-150"
                  >
                    Eliminar
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No hay empleados disponibles.</p>
        )}
      </div>
    </div>
  );
}

export default Empleados;
