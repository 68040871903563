import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchWithAuth } from '../utils/apiHelper';

const ConfirmarTurno = () => {
  const { turno_id } = useParams();
  const [clientArrived, setClientArrived] = useState(false);
  const [turnoInfo, setTurnoInfo] = useState(null); // Initialize as null to distinguish between loading and no data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTurnoInfo = async () => {
      try {
        const response = await fetchWithAuth(`/turnos/estado/turno/${turno_id}`, {
          method: 'GET',
        });
        setTurnoInfo(response || null); // If no response, set to null
        setLoading(false);
      } catch (error) {
        console.error('Error fetching turno info:', error);
        setTurnoInfo(null); // Set to null in case of error
        setLoading(false);
      }
    };

    fetchTurnoInfo();
  }, [turno_id]);

  const handleClientArrival = async (event) => {
    event.preventDefault();
    try {
      await fetchWithAuth(`/turnos/cliente/confirmar/${turno_id}`, {
        method: 'POST',
      });
      setClientArrived(true);
      setTurnoInfo((prevInfo) => ({ ...prevInfo, estado: 'Confirmado' }));
    } catch (error) {
      console.error('Error confirming client arrival:', error);
    }
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    try {
      await fetchWithAuth(`/turnos/cliente/cancelar/${turno_id}`, {
        method: 'POST',
      });
      setClientArrived(true);
      setTurnoInfo((prevInfo) => ({ ...prevInfo, estado: 'Cancelado' }));
    } catch (error) {
      console.error('Error cancelling turno:', error);
    }
  };

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen">Cargando...</div>;
  }

  if (!turnoInfo) {
    return <div className="flex items-center justify-center min-h-screen">No se encontró el turno.</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 p-4">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg">
        <h1 className="text-3xl font-bold mb-6 text-center text-blue-800">Confirmar Turno</h1>
        <div className="mb-6">
          <p className="text-lg font-semibold text-gray-700">Servicio: <span className="text-gray-900">{turnoInfo.servicio}</span></p>
          <p className="text-lg font-semibold text-gray-700">Fecha: <span className="text-gray-900">{new Date(turnoInfo.fecha).toLocaleDateString()}</span></p>
          <p className="text-lg font-semibold text-gray-700">Hora: <span className="text-gray-900">{turnoInfo.hora}</span></p>
          <p className="text-lg font-semibold text-gray-700">Estado: <span className={`text-lg font-semibold ${turnoInfo.estado === 'Confirmado' ? 'text-green-600' : turnoInfo.estado === 'Cancelado' ? 'text-red-600' : 'text-yellow-600'}`}>{turnoInfo.estado}</span></p>
        </div>

        {turnoInfo.estado === 'Confirmado' && (
          <p className="text-center text-green-600 font-semibold text-xl">El turno ha sido confirmado.</p>
        )}
        {turnoInfo.estado === 'Cancelado' && (
          <p className="text-center text-red-600 font-semibold text-xl">El turno ha sido cancelado.</p>
        )}
        {turnoInfo.estado === 'Reservado' && !clientArrived && (
          <>
            <p className="mb-6 text-center text-gray-600 text-lg">¿Deseas confirmar tu llegada para el turno?</p>
            <div className="flex justify-between">
              <button
                onClick={handleClientArrival}
                className="w-full bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition duration-200 mr-2"
              >
                Confirmar
              </button>
              <button
                onClick={handleCancel}
                className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition duration-200 ml-2"
              >
                Cancelar
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmarTurno;
