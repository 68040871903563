import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ArqueoCaja = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchArqueoCaja = async () => {
    if (!startDate || !endDate) {
      toast.error('Por favor, selecciona un rango de fechas.');
      return;
    }

    setLoading(true);
    try {
      const result = await fetchWithAuth(`/caja/arqueo-caja?startDate=${startDate}&endDate=${endDate}`);
      setData(result);
    } catch (error) {
      console.error('Error fetching arqueo de caja:', error);
      toast.error('Error al obtener el arqueo de caja.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Arqueo de Caja</h1>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Fecha de inicio</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="mt-1 block w-full p-2 border rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Fecha de fin</label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="mt-1 block w-full p-2 border rounded"
        />
      </div>
      <button
        onClick={fetchArqueoCaja}
        className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
        disabled={loading}
      >
        {loading ? 'Cargando...' : 'Obtener Arqueo de Caja'}
      </button>
      <ToastContainer />
      {data && (
        <div className="mt-6">
          <h2 className="text-xl font-bold mb-4">Resumen</h2>
          <p>Total Ingresos: ${data.total_ingresos}</p>
          <p>Total Egresos: ${data.total_egresos}</p>
          <p>Total Esperado: ${data.expected_total}</p>
          <p>Total en Caja: ${data.actual_total}</p>
          <p>Diferencia: ${data.difference}</p>
          <h2 className="text-xl font-bold mt-6 mb-4">Transacciones</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Fecha</th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Hora</th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Monto</th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Tipo</th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Descripción</th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Usuario</th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-700">Empleado</th>
                </tr>
              </thead>
              <tbody>
                {data.transactions.map((trans) => (
                  <tr key={trans.transaccion_id}>
                    <td className="px-6 py-4 border-b border-gray-200">{trans.fecha}</td>
                    <td className="px-6 py-4 border-b border-gray-200">{trans.hora}</td>
                    <td className={`px-6 py-4 border-b border-gray-200 ${trans.tipo_transaccion === 'Ingreso' ? 'text-green-500' : 'text-red-500'}`}>
                      {trans.tipo_transaccion === 'Ingreso' ? '+' : '-'}${trans.monto}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">{trans.tipo_transaccion}</td>
                    <td className="px-6 py-4 border-b border-gray-200">{trans.descripcion}</td>
                    <td className="px-6 py-4 border-b border-gray-200">{trans.usuario}</td>
                    <td className="px-6 py-4 border-b border-gray-200">{trans.empleado}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArqueoCaja;
