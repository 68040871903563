import React from 'react';
import { Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { fetchWithAuth } from '../utils/apiHelper';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom




const TrabajosList = ({ data, totales, fetchData }) => {

  const calculateTotalWithSurcharge = (amount, tiene_recargo, valor_recargo) => {
    return tiene_recargo ? amount * (1 + valor_recargo / 100) : amount;
  };

  const updatedData = data.map(item => ({
    ...item,
    monto: calculateTotalWithSurcharge(item.monto, item.tiene_recargo, item.valor_recargo),
  }));

  console.log(updatedData)
  const updatedTotales = Object.keys(totales).reduce((acc, metodo) => {
    const methodData = data.find(item => item.metodo_pago === metodo);
    const totalAmount = totales[metodo];
    const totalWithSurcharge = methodData ? calculateTotalWithSurcharge(totalAmount, methodData.tiene_recargo, methodData.valor_recargo) : totalAmount;
    acc[metodo] = totalWithSurcharge;
    return acc;
  }, {});

  const handleDelete = async (id) => {
    try {
      await fetchWithAuth(`/operaciones/facturados/${id}`, {
        method: 'DELETE',
      });
      toast.success('Registro eliminado exitosamente.');
      fetchData('facturados');
    } catch (error) {
      console.error('Error eliminando el registro:', error);
      toast.error('Error al eliminar el registro.');
    }
  };

  return (
    <Transition
      show={updatedData.length > 0}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-white p-4 md:p-10 rounded-lg shadow-lg">
        <h3 className="text-xl md:text-3xl font-bold mb-4 md:mb-6 text-blue-800">Resultados</h3>
        <div className="overflow-x-auto mb-4 md:mb-8">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Fecha del Turno</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Empleado</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Cliente</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Servicio</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Monto</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Método de Pago</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {updatedData.map((item) => (
                <tr key={item.pago_id} className="hover:bg-gray-100 transition-colors">
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">{new Date(item.turno_fecha).toLocaleDateString()}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">{item.empleado_nombre}</td>
                  
                  
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">
                    {item.cliente_id ? (
                      <Link
                        to={`/detalles/cliente/${item.cliente_id}`}
                        className="font-medium text-indigo-400 hover:text-indigo-500"
                      >
                        {item.cliente_nombre} {item.cliente_apellido}
                      </Link>
                    ) : (
                      'Cliente no registrado'
                    )}
                  </td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200">{item.servicio_nombre}</td>
                  
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">${item.monto.toLocaleString()}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200">{item.metodo_pago}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200">
                    <button
                      onClick={() => handleDelete(item.pago_id)}
                      className="px-3 md:px-4 py-1 md:py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {Object.keys(updatedTotales).length > 0 && (
          <div className="bg-blue-50 p-4 md:p-6 rounded-lg shadow-lg">
            <h3 className="text-lg md:text-2xl font-bold mb-2 md:mb-4 text-blue-800">Resumen de Totales</h3>
            <ul className="space-y-2 md:space-y-3">
              {Object.keys(updatedTotales).map((metodo) => (
                <li key={metodo} className="flex justify-between items-center">
                  <span className="text-base md:text-xl font-semibold text-gray-700">{metodo}:</span>
                  <span className="text-base md:text-xl font-semibold text-gray-900">${updatedTotales[metodo].toLocaleString()}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Transition>
  );
};

export default TrabajosList;
