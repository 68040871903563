import React, { useState } from 'react';
import TrabajosList from './TrabajosList';
import OperacionesList from './OperacionesList';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithAuth } from '../utils/apiHelper';

const DataList = () => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(tomorrow);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totales, setTotales] = useState({});
  const [currentView, setCurrentView] = useState('Trabajos');

  const fetchData = async (endpoint) => {
    if (!startDate || !endDate) {
      toast.error('Por favor, selecciona ambas fechas.');
      return;
    }

    setLoading(true);

    try {
      const startDateUTC = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];
      const endDateUTC = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];

      const data = await fetchWithAuth(`/operaciones/${endpoint}?fecha_inicio=${startDateUTC}&fecha_fin=${endDateUTC}`);
      setData(data);
      console.log(data)

      if (endpoint === 'facturados') {
        const totalesPorMetodo = data.reduce((acc, curr) => {
          acc[curr.metodo_pago] = (acc[curr.metodo_pago] || 0) + curr.monto;
          return acc;
        }, {});
        const totalGeneral = data.reduce((acc, curr) => acc + curr.monto, 0);
        setTotales({ ...totalesPorMetodo, Total: totalGeneral });
      } else if (endpoint === 'operations') {
        const totalesPorTipo = data.reduce((acc, curr) => {
          let valor = curr.costo;
          if (['Comision a empleado', 'Compra de insumos', 'Pago de viaticos a empleado', 'Retiro de efectivo', 'Vale a empleado'].includes(curr.tipo)) {
            valor = -curr.costo;
          }
          acc[curr.tipo] = (acc[curr.tipo] || 0) + valor;
          return acc;
        }, {});
        const totalGeneral = data.reduce((acc, curr) => {
          let valor = curr.costo;
          if (['Comision a empleado', 'Compra de insumos', 'Pago de viaticos a empleado', 'Retiro de efectivo', 'Vale a empleado'].includes(curr.tipo)) {
            valor = -curr.costo;
          }
          return acc + valor;
        }, 0);
        setTotales({ ...totalesPorTipo, Total: totalGeneral });
      }

    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      toast.error(`Error al recuperar los ${endpoint}.`);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchTrabajos = () => {
    setCurrentView('Trabajos');
    fetchData('facturados');
  };

  const handleFetchOperaciones = () => {
    setCurrentView('Operaciones');
    fetchData('operations');
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h2 className="text-4xl font-extrabold mb-8 text-center text-blue-800">Datos</h2>
      <div className="flex justify-center space-x-4 mb-8">
        <button
          onClick={handleFetchTrabajos}
          className={`px-6 py-3 rounded-lg text-white font-semibold ${currentView === 'Trabajos' ? 'bg-blue-700' : 'bg-blue-600 hover:bg-blue-700'} transition-transform transform hover:scale-105`}
        >
          Trabajos
        </button>
        <button
          onClick={handleFetchOperaciones}
          className={`px-6 py-3 rounded-lg text-white font-semibold ${currentView === 'Operaciones' ? 'bg-green-700' : 'bg-green-600 hover:bg-green-700'} transition-transform transform hover:scale-105`}
        >
          Operaciones
        </button>
      </div>
      <div className="bg-white p-10 rounded-lg shadow-lg mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="mb-2 text-gray-700 font-semibold">Fecha Inicio</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
              dateFormat="yyyy-MM-dd"
              placeholderText="Selecciona la fecha de inicio"
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-2 text-gray-700 font-semibold">Fecha Fin</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
              dateFormat="yyyy-MM-dd"
              placeholderText="Selecciona la fecha de fin"
            />
          </div>
        </div>
        <div className="mt-6 flex justify-end">
          <button
            onClick={() => {
              if (currentView === 'Trabajos') {
                handleFetchTrabajos();
              } else {
                handleFetchOperaciones();
              }
            }}
            className={`px-6 py-3 rounded-lg text-white font-semibold transition-transform transform hover:scale-105 ${!startDate || !endDate ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
            disabled={!startDate || !endDate}
          >
            Buscar
          </button>
        </div>
      </div>

      {loading ? (
        <div className="text-center">
          <svg className="animate-spin h-8 w-8 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
          </svg>
        </div>
      ) : (
        currentView === 'Trabajos' ? <TrabajosList data={data} totales={totales} fetchData={fetchData} /> : <OperacionesList data={data} totales={totales} fetchData={fetchData} />
      )}
    </div>
  );
};

export default DataList;
