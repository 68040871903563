import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper'; // Import the fetchWithAuth function
import { toast } from 'react-toastify'; // Import toast from react-toastify

function Servicios() {
  const [servicios, setServicios] = useState([]);
  const [nombre, setNombre] = useState('');
  const [importe, setImporte] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  useEffect(() => {
    fetchServicios();
  }, []);

  useEffect(() => {
    setFilteredServices(
      servicios.filter(servicio =>
        servicio.nombre.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, servicios]);

  const fetchServicios = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithAuth('/services/');
      setServicios(data);
    } catch (error) {
      console.error('Error al obtener los servicios:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddServicio = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newServicio = { nombre, importe };
    try {
      await fetchWithAuth('/services/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newServicio)
      });
      fetchServicios(); // Refetch services after adding a new one
      resetForm();
      toast.success('Servicio creado con éxito!');
    } catch (error) {
      toast.error('Error al crear el servicio');
      console.error('Error al añadir el servicio:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteServicio = async (servicio_id) => {
    setIsLoading(true);
    try {
      await fetchWithAuth(`/services/${servicio_id}`, {
        method: 'DELETE'
      });
      fetchServicios(); // Refetch services after deleting one
      toast.success('Servicio eliminado con éxito!');
    } catch (error) {
      toast.error('Error al eliminar el servicio');
      console.error('Error al eliminar el servicio:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateServicio = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const updatedServicio = { ...selectedService, nombre, importe };
    try {
      await fetchWithAuth(`/services/${selectedService.servicio_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedServicio)
      });
      fetchServicios(); // Refetch services after updating one
      resetForm();
      toast.success('Servicio actualizado con éxito!');
    } catch (error) {
      toast.error('Error al actualizar el servicio');
      console.error('Error al actualizar el servicio:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedService(null);
    setNombre('');
    setImporte('');
    setShowForm(false);
  };

  const handleSelectService = (servicio) => {
    setSelectedService(servicio);
    setNombre(servicio.nombre);
    setImporte(servicio.precio);
    setShowForm(true);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Gestión de Servicios</h1>
      <div className="mb-6 flex justify-between items-center">
        <button
          onClick={() => {
            setShowForm(!showForm);
            if (showForm) {
              resetForm();
            }
          }}
          disabled={isLoading}
          className={`px-6 py-2 ${isLoading ? 'bg-gray-500' : 'bg-indigo-600'} text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150`}
        >
          {showForm ? 'Cancelar' : 'Crear Nuevo Servicio'}
        </button>
        <input
          type="text"
          placeholder="Buscar servicios por nombre..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="mt-1 p-2 w-1/2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      {showForm && (
        <form onSubmit={selectedService ? handleUpdateServicio : handleAddServicio} className="mb-6 p-6 bg-white rounded-lg shadow-lg space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Nombre</label>
            <input
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Importe</label>
            <input
              type="number"
              value={importe}
              onChange={(e) => setImporte(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full py-2 ${isLoading ? 'bg-gray-500' : 'bg-green-600'} text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150`}
          >
            {selectedService ? 'Actualizar Servicio' : 'Añadir Servicio'}
          </button>
        </form>
      )}
      <div>
        <h2 className="text-xl font-bold mb-4">Lista de Servicios</h2>
        {filteredServices.length > 0 ? (
          <ul className="space-y-4">
            {filteredServices.map((servicio) => (
              <li key={servicio.servicio_id} className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition duration-150">
                <div className="flex justify-between items-center">
                  <div onClick={() => handleSelectService(servicio)} className="cursor-pointer">
                    <span className="font-medium text-gray-800">{servicio.nombre}</span>
                    <span className="text-gray-600 ml-2">${servicio.precio}</span>
                  </div>
                  <button
                    onClick={() => handleDeleteServicio(servicio.servicio_id)}
                    disabled={isLoading}
                    className={`ml-4 px-4 py-2 ${isLoading ? 'bg-gray-500' : 'bg-red-600'} text-white rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-150`}
                  >
                    Eliminar
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No hay servicios disponibles.</p>
        )}
      </div>
    </div>
  );
}

export default Servicios;
