import React, { useState, useEffect, useCallback } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BookingModal from './BookingModal';
import SinglePaymentModal from './SinglePaymentModal';
import useSchedule from './useSchedule'; // Assuming this is a custom hook for fetching schedules
import CalendarComponent from './CalendarComponent';
import ScheduleTable from './ScheduleTable';

moment.locale('es');
const localizer = momentLocalizer(moment);

function MyCalendar() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ employee: null, date: null, time: null });

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const {
    employees,
    appointments,
    loading,
    sucursalData,
    fetchEmployeesAndSchedules,
    fetchAppointments,
    clearAppointments,
    fetchHorariosLocal
  } = useSchedule(selectedDate);

  useEffect(() => {
    fetchEmployeesAndSchedules();
    fetchAppointments(selectedDate);
    fetchHorariosLocal();
  }, [selectedDate, fetchEmployeesAndSchedules, fetchAppointments]);

  const handleNewBooking = () => {
    fetchAppointments(selectedDate);
  };

  const openModal = useCallback((employee, date, time) => {
    setModalData({ employee, date, time });
    setIsModalOpen(true);
  }, []);

  const handleBookingClick = (employee, time) => {
    openModal(employee, moment(selectedDate).format('YYYY-MM-DD'), time);
  };

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(start);
    clearAppointments(); // Clear current appointments before fetching new ones
    fetchAppointments(start); // Fetch new appointments
  };

  const dayPropGetter = useCallback((date) => {
    const isSelectedDate = moment(date).isSame(selectedDate, 'day');
    return {
      style: {
        backgroundColor: isSelectedDate ? '#ffc107' : '',
        color: isSelectedDate ? 'white' : 'black'
      }
    };
  }, [selectedDate]);

  return (
    <div className={`${loading ? 'cursor-progress' : 'cursor-default'} w-full  mx-auto px-4 sm:px-6 lg:px-8 py-6`}>
      <CalendarComponent
        localizer={localizer}
        onSelectSlot={handleSelectSlot}
        dayPropGetter={dayPropGetter}
        selectedDate={selectedDate}
      />
      <div className="mt-4 bg-salon-blue text-center text-xl font-semibold p-4 rounded-lg shadow-lg border-2 border-salon-pink">
        <span className="text-salon-dark-purple">
          Turnos del día {moment(selectedDate).format('dddd D [del mes de] MMMM [del año] YYYY')}
        </span>
      </div>

      <button
        className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition-transform transform hover:scale-105"
        onClick={() => setIsPaymentModalOpen(true)}
      >
        Crear Pago
      </button>

      <ScheduleTable
        sucursal={sucursalData}
        appointments={appointments}
        employees={employees}
        dia={moment(selectedDate).format('dddd')}
        onBookingClick={handleBookingClick}
        updateAppointments={handleNewBooking}
      />

      {isModalOpen && (
        <BookingModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          data={modalData}
          updateAppointments={handleNewBooking}
        />
      )}

      {isPaymentModalOpen && (
        <SinglePaymentModal
          isOpen={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          loadingState={false} // Add appropriate state or logic for loading
          updateAppointments={handleNewBooking}
        />
      )}
    </div>
  );
}

export default MyCalendar;
