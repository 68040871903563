import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Comisiones() {
  const [data, setData] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [commissionAdjustments, setCommissionAdjustments] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const isDisabled = selectedServices.length === 0 || !selectedEmployee;

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchWithAuth('/administracion/');
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
    setSelectedServices([]);
    setCommissionAdjustments({});
  };

  const handleSelectService = (service) => {
    setSelectedServices((prevSelected) => {
      if (prevSelected.includes(service)) {
        return prevSelected.filter((s) => s !== service);
      } else {
        return [...prevSelected, service];
      }
    });
  };

  const handleSelectAllServices = () => {
    if (selectedServices.length === data.services.length) {
      setSelectedServices([]);
    } else {
      setSelectedServices(data.services);
    }
  };

  const handleCommissionChange = (amount) => {
    setCommissionAdjustments((prev) => {
      const newAdjustments = { ...prev };
      selectedServices.forEach(service => {
        const currentCommission = newAdjustments[service.servicio_id] !== undefined
          ? newAdjustments[service.servicio_id]
          : service.comisiones.find(c => c.empleado_id === selectedEmployee.empleado_id)?.comision || 0;
        const newCommission = Math.max(0, Math.min(100, parseInt(currentCommission, 10) + parseInt(amount, 10)));
        newAdjustments[service.servicio_id] = newCommission;
      });
      return newAdjustments;
    });
  };

  const handleResetCommission = () => {
    setCommissionAdjustments((prev) => {
      const newAdjustments = { ...prev };
      selectedServices.forEach(service => {
        newAdjustments[service.servicio_id] = 0;
      });
      return newAdjustments;
    });
  };

  const handleSaveCommission = async () => {
    setIsSaving(true);
    try {
      const response = await fetchWithAuth('/administracion/comision/update', {
        method: 'POST',
        body: JSON.stringify({
          empleado_id: selectedEmployee.empleado_id,
          servicios: selectedServices.map(service => ({
            servicio_id: service.servicio_id,
            comision: commissionAdjustments[service.servicio_id] !== undefined
              ? commissionAdjustments[service.servicio_id]
              : service.comisiones.find(c => c.empleado_id === selectedEmployee.empleado_id)?.comision || 0
          }))
        })
      });

      if (response.success) {
        toast.success('Comisión actualizada correctamente!');

        // Fetch the updated data while preserving the selected employee
        const updatedData = await fetchWithAuth('/administracion/');
        setData(updatedData);
        
        // Maintain the selection state of the services
        const updatedServices = updatedData.services.filter(service => selectedServices.some(s => s.servicio_id === service.servicio_id));
        setSelectedServices(updatedServices);

        setCommissionAdjustments({});
      } else {
        toast.error('Error al actualizar la comisión.');
        console.error('Error updating commission:', response.message);
      }
    } catch (error) {
      toast.error('Error al actualizar la comisión.');
      console.error('Error updating commission:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {!data ? (
        <div className="flex justify-center items-center h-screen bg-gray-50">
          <div className="text-center">
            <svg className="animate-spin h-10 w-10 text-blue-500 mx-auto mb-4" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 11-8 8 8 8 0 01-8-8z"></path>
            </svg>
            <p className="text-xl font-semibold text-gray-600">Cargando...</p>
          </div>
        </div>
      ) : (
        <div className="container mx-auto p-8 bg-gray-100 min-h-screen">
          <h1 className="text-5xl font-bold text-center text-gray-800 mb-12">Comisiones de Empleados</h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="col-span-1 bg-white shadow-lg rounded-lg p-8">
              <h2 className="text-3xl font-semibold text-gray-700 mb-6">Empleados</h2>
              <ul className="divide-y divide-gray-300">
                {data.employees.map((employee) => (
                  <li
                    key={employee.empleado_id}
                    className={`p-4 cursor-pointer rounded-lg transition duration-200 ${selectedEmployee?.empleado_id === employee.empleado_id ? 'bg-blue-100' : 'hover:bg-blue-50'}`}
                    onClick={() => handleSelectEmployee(employee)}
                  >
                    <p className="text-lg font-medium text-gray-800">{employee.nombre} {employee.apellido}</p>
                  </li>
                ))}
              </ul>
            </div>
            {selectedEmployee && (
              <>
                <div className="col-span-1 bg-white shadow-lg rounded-lg p-8">
                  <h2 className="text-3xl font-semibold text-gray-700 mb-6">Servicios</h2>
                  <div className="mb-6">
                    <button className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 w-full transition duration-200" onClick={handleSelectAllServices}>
                      {selectedServices.length === data.services.length ? 'Deseleccionar Todos los Servicios' : 'Seleccionar Todos los Servicios'}
                    </button>
                  </div>
                  <ul className="divide-y divide-gray-300">
                    {data.services.map((service) => {
                      const currentCommission = commissionAdjustments[service.servicio_id] !== undefined
                        ? commissionAdjustments[service.servicio_id]
                        : service.comisiones.find(c => c.empleado_id === selectedEmployee.empleado_id)?.comision || 0;
                      const comisionEmpleado = parseFloat(service.precio) * (currentCommission / 100);
                      return (
                        <li
                          key={service.servicio_id}
                          className={`p-4 cursor-pointer rounded-lg transition duration-200 ${selectedServices.includes(service) ? 'bg-green-100' : 'hover:bg-green-50'}`}
                          onClick={() => handleSelectService(service)}
                        >
                          <div className="flex justify-between items-center">
                            <div>
                              <p className="text-xl font-medium text-gray-900">{service.nombre}</p>
                              <p className="text-gray-600">{`Precio: $${parseFloat(service.precio).toFixed(2)}`}</p>
                              <p className="text-gray-600">{`Comisión Empleada: $${comisionEmpleado.toFixed(2)}`}</p>
                            </div>
                            <p className="text-lg font-semibold text-gray-700">
                              {currentCommission}%
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-span-1 bg-white shadow-lg rounded-lg p-8">
                  <h2 className="text-3xl font-semibold text-gray-700 mb-6">Ajustar Comisión</h2>
                  {selectedServices.length > 0 ? (
                    <div>
                      <div className="mb-6">
                        <p className="text-xl font-bold text-gray-900">Empleado: {selectedEmployee.nombre} {selectedEmployee.apellido}</p>
                        <p className="text-xl text-gray-700">Servicios Seleccionados: <span className="font-semibold">{selectedServices.length}</span></p>
                      </div>
                      <div className="grid grid-cols-2 gap-6 mb-6">
                        <button className={`py-3 px-6 rounded-lg transition duration-200 ${isDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-green-600 text-white hover:bg-green-700'}`} onClick={() => !isDisabled && handleCommissionChange(1)} disabled={isDisabled}>+1%</button>
                        <button className={`py-3 px-6 rounded-lg transition duration-200 ${isDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-green-600 text-white hover:bg-green-700'}`} onClick={() => !isDisabled && handleCommissionChange(5)} disabled={isDisabled}>+5%</button>
                        <button className={`py-3 px-6 rounded-lg transition duration-200 ${isDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-green-600 text-white hover:bg-green-700'}`} onClick={() => !isDisabled && handleCommissionChange(10)} disabled={isDisabled}>+10%</button>
                        <button className={`py-3 px-6 rounded-lg transition duration-200 ${isDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-green-600 text-white hover:bg-green-700'}`} onClick={() => !isDisabled && handleCommissionChange(50)} disabled={isDisabled}>+50%</button>
                        <button className={`py-3 px-6 rounded-lg transition duration-200 ${isDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-red-600 text-white hover:bg-red-700'}`} onClick={() => !isDisabled && handleResetCommission()} disabled={isDisabled}>Resetear Comisión</button>
                      </div>
                      <button
                        className={`w-full ${isSaving ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'} text-white py-3 px-6 rounded-lg transition duration-200`}
                        onClick={handleSaveCommission}
                        disabled={isSaving}
                      >
                        {isSaving ? 'Guardando...' : 'Guardar'}
                      </button>
                    </div>
                  ) : (
                    <div className="text-center text-gray-500">Selecciona al menos un servicio para ajustar la comisión.</div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Comisiones;
