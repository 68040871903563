import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { fetchWithAuth } from '../utils/apiHelper';

const PaymentModal = ({ isOpen, onClose, appointment, onPay, loadingState }) => {
  const [paymentMethod, setPaymentMethod] = useState('Efectivo');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedServicesByEmployee, setSelectedServicesByEmployee] = useState({});
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sameDayTurnos, setSameDayTurnos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchServices();
      await fetchPaymentMethods();
      await fetchEmpleados();
    };

    fetchData();
  }, [appointment]);

  useEffect(() => {
    if (employees.length > 0) {
      initializeSelectedServices();
    }
  }, [employees, sameDayTurnos]);

  const fetchServices = async () => {
    try {
      const data = await fetchWithAuth('/services/');
      setServices(data);
      fetchSameDayTurnos(data);
    } catch (error) {
      console.error('Error fetching services:', error);
      toast.error('Error al obtener los servicios.');
    }
  };

  const fetchPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithAuth('/administracion/metodos/pago', {
        method: 'GET',
      });
      setPaymentMethods(data);
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      toast.error('Error al obtener los métodos de pago.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSameDayTurnos = async (servicesData) => {
    try {
      const formattedDate = new Date(appointment.fecha).toISOString().split('T')[0];
      const data = await fetchWithAuth(`/turnos/cliente/${appointment.cliente_id}/fecha/${formattedDate}?turno_id=${appointment.turno_id}`, {
        method: 'GET',
      });
      const sameDayTurnosData = data.filter(turno => turno.turno_id !== appointment.turno_id);
      setSameDayTurnos(sameDayTurnosData);
    } catch (error) {
      console.error('Error fetching same day turnos:', error);
      toast.error('Error al obtener los turnos del mismo día.');
    }
  };

  const fetchEmpleados = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithAuth('/empleados/');
      setEmployees(data);
    } catch (error) {
      console.error('Error al obtener los empleados:', error);
      toast.error('Error al obtener los empleados.');
    } finally {
      setIsLoading(false);
    }
  };

  const initializeSelectedServices = () => {
    const initialSelectedServices = {};
    const appointmentEmployee = employees.find(emp => emp.empleado_id === appointment.empleado_id);

    if (appointmentEmployee) {
      initialSelectedServices[appointment.empleado_id] = [
        {
          servicio_id: appointment.servicio_id,
          nombre: services.find(s => s.servicio_id === appointment.servicio_id)?.nombre || '',
          precio: services.find(s => s.servicio_id === appointment.servicio_id)?.precio || 0,
          count: 1,
          empleado_id: appointment.empleado_id
        }
      ];
    }

    sameDayTurnos.forEach(turno => {
      const turnoEmployee = employees.find(emp => emp.empleado_id === turno.empleado_id);
      if (turnoEmployee) {
        if (!initialSelectedServices[turno.empleado_id]) {
          initialSelectedServices[turno.empleado_id] = [];
        }
        initialSelectedServices[turno.empleado_id].push({
          servicio_id: turno.servicio_id,
          nombre: services.find(s => s.servicio_id === turno.servicio_id)?.nombre || '',
          precio: services.find(s => s.servicio_id === turno.servicio_id)?.precio || 0,
          count: 1,
          empleado_id: turno.empleado_id
        });
      }
    });

    setSelectedServicesByEmployee(initialSelectedServices);

    if (appointmentEmployee) {
      setSelectedEmployee(appointmentEmployee);
    }
  };

  const handleEmployeeSelection = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleServiceSelection = (service) => {
    if (!selectedEmployee) return;

    setSelectedServicesByEmployee(prev => {
      const employeeServices = prev[selectedEmployee.empleado_id] || [];
      const existingService = employeeServices.find(s => s.servicio_id === service.servicio_id);

      if (existingService) {
        return {
          ...prev,
          [selectedEmployee.empleado_id]: employeeServices.map(s =>
            s.servicio_id === service.servicio_id ? { ...s, count: s.count + 1 } : s
          )
        };
      } else {
        return {
          ...prev,
          [selectedEmployee.empleado_id]: [...employeeServices, { ...service, count: 1, empleado_id: selectedEmployee.empleado_id }]
        };
      }
    });
  };

  const handleServiceDeselection = (service) => {
    if (!selectedEmployee) return;

    setSelectedServicesByEmployee(prev => {
      const employeeServices = prev[selectedEmployee.empleado_id] || [];
      const existingService = employeeServices.find(s => s.servicio_id === service.servicio_id);

      if (existingService.count > 1) {
        return {
          ...prev,
          [selectedEmployee.empleado_id]: employeeServices.map(s =>
            s.servicio_id === service.servicio_id ? { ...s, count: s.count - 1 } : s
          )
        };
      } else {
        return {
          ...prev,
          [selectedEmployee.empleado_id]: employeeServices.filter(s => s.servicio_id !== service.servicio_id)
        };
      }
    });
  };

  const handleConfirmPay = async () => {
    const selectedServiceIds = Object.values(selectedServicesByEmployee).flat().flatMap(service => 
      Array(service.count).fill({ servicio_id: service.servicio_id, monto: service.precio, empleado_id: service.empleado_id })
    );
    await onPay(appointment.turno_id, paymentMethod, selectedServiceIds);
    
    onClose();
  };

  const totalAmount = Object.values(selectedServicesByEmployee).flat().reduce((total, service) => {
    return total + (service?.precio || 0) * service.count;
  }, 0);

  const selectedPaymentMethod = paymentMethods.find(method => method.nombre === paymentMethod);
  const surchargeAmount = selectedPaymentMethod && selectedPaymentMethod.tiene_recargo ? (totalAmount * selectedPaymentMethod.valor_recargo) / 100 : 0;
  const totalAmountWithSurcharge = totalAmount + surchargeAmount;

  const filteredServices = services.filter(service =>
    service.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort services to have selected services at the top
  const sortedServices = filteredServices.sort((a, b) => {
    const aSelected = (selectedServicesByEmployee[selectedEmployee?.empleado_id] || []).find(s => s.servicio_id === a.servicio_id);
    const bSelected = (selectedServicesByEmployee[selectedEmployee?.empleado_id] || []).find(s => s.servicio_id === b.servicio_id);
    return (bSelected ? 1 : 0) - (aSelected ? 1 : 0);
  });

  return (
    <Transition show={isOpen} className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
        </Transition.Child>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <Transition.Child className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full max-h-screen">
          <div className="bg-white p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4 text-center">Realizar Pago</h2>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Método de Pago
              </label>
              <select
                className="w-full p-2 border rounded"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                {paymentMethods.map((method) => (
                  <option key={method.metodo_id} value={method.nombre}>
                    {method.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Empleado
              </label>
              <div className="relative">
                <select
                  className="w-full p-2 border rounded appearance-none"
                  value={selectedEmployee ? selectedEmployee.empleado_id : ''}
                  onChange={(e) => {
                    const selected = employees.find(emp => emp.empleado_id === e.target.value);
                    handleEmployeeSelection(selected);
                  }}
                >
                  <option value="" disabled>Seleccione un empleado</option>
                  {employees.map((employee) => (
                    <option key={employee.empleado_id} value={employee.empleado_id}>
                      {employee.nombre}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.292 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Buscar Servicios
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="mb-4 max-h-48 overflow-y-auto">
              <label className="block mb-2 text-sm font-bold text-gray-700">
                Selecciona Servicios
              </label>
              {sortedServices.map((service, index) => (
                <div
                  key={service.servicio_id}
                  className={`flex items-center justify-between mb-2 p-2 border rounded ${selectedServicesByEmployee[selectedEmployee?.empleado_id]?.some(s => s.servicio_id === service.servicio_id) ? 'bg-blue-100' : 'bg-gray-100'}`}
                >
                  <div>
                    <span>{service.nombre} - ${service.precio}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    {selectedServicesByEmployee[selectedEmployee?.empleado_id]?.find(s => s.servicio_id === service.servicio_id)?.count > 0 && (
                      <button
                        className="px-2 py-1 bg-red-500 text-white rounded"
                        onClick={() => handleServiceDeselection(service)}
                      >
                        -
                      </button>
                    )}
                    <span>{selectedServicesByEmployee[selectedEmployee?.empleado_id]?.find(s => s.servicio_id === service.servicio_id)?.count || 0}</span>
                    <button
                      className="px-2 py-1 bg-blue-500 text-white rounded"
                      onClick={() => handleServiceSelection(service)}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {Object.keys(selectedServicesByEmployee).map(employeeId => {
              const employee = employees.find(emp => emp.empleado_id === employeeId);
              const services = selectedServicesByEmployee[employeeId] || [];
              return services.length > 0 ? (
                <div key={employeeId} className="mt-4 p-4 bg-gray-100 border border-gray-300 rounded-lg">
                  <h3 className="text-lg font-bold">{employee.nombre}</h3>
                  <ul className="list-disc ml-5 mt-2">
                    {services.map(service => (
                      <li key={service.servicio_id}>
                        {service.nombre} - ${service.precio} x {service.count}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null;
            })}
            <div className="mt-4">
              <h3 className="text-lg font-bold">Total: ${totalAmountWithSurcharge.toFixed(2)}</h3>
              {selectedPaymentMethod && selectedPaymentMethod.tiene_recargo && (
                <p className="text-sm text-gray-500">
                  Incluye un recargo del {selectedPaymentMethod.valor_recargo}% por pago con {selectedPaymentMethod.nombre}
                </p>
              )}
            </div>
            {sameDayTurnos.length > 0 && (
              <div className="mt-4 p-4 bg-yellow-100 border border-yellow-400 rounded-lg">
                <h3 className="text-lg font-bold text-yellow-800">Otros turnos para el mismo día:</h3>
                <ul className="list-disc ml-5 mt-2">
                  {sameDayTurnos.map(turno => (
                    <li key={turno.turno_id}>
                      {turno.hora} - {turno.servicio_nombre} con {turno.empleado_nombre}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="mt-4 flex justify-end space-x-4">
              <button 
                onClick={onClose} 
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-lg transition-transform transform hover:scale-105"
              >
                Cerrar
              </button>
              <button 
                onClick={handleConfirmPay} 
                className={`bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition-transform transform hover:scale-105 ${loadingState === 'paying' || !selectedEmployee || selectedServicesByEmployee[selectedEmployee.empleado_id]?.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loadingState === 'paying' || !selectedEmployee || selectedServicesByEmployee[selectedEmployee.empleado_id]?.length === 0}
              >
                {loadingState === 'paying' ? 'Procesando...' : 'Confirmar Pago'}
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

export default PaymentModal;
