const API_HOST = process.env.REACT_APP_API_HOST;
const API_PORT = process.env.REACT_APP_API_PORT;
const BASE_URL = `${API_HOST}:${API_PORT}/api`;
const API_BASE_URL = 'https://narella.nistal.net/api';





export const fetchWithAuth = async (endpoint, options = {}) => {
  const token = localStorage.getItem('token');
  const headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  };

  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, { ...options, headers });

    if (response.status === 401) {
      // Handle 401 error
      localStorage.clear();
      window.location.href = '/login'; // Redirect to login
      throw new Error('Unauthorized');
    }

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData.error || 'Something went wrong');
    }

    return responseData;
  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
};


/*



import jwtDecode from 'jwt-decode'; // Importa la librería jwt-decode para decodificar el token JWT

const API_HOST = process.env.REACT_APP_API_HOST;
const API_PORT = process.env.REACT_APP_API_PORT;
const BASE_URL = `${API_HOST}:${API_PORT}/api`;

export const fetchWithAuth = async (endpoint, options = {}) => {
  let token = localStorage.getItem('token');
  let tokenExpireTime = localStorage.getItem('tokenExpireTime'); // Obtiene el tiempo de expiración del token de localStorage

  // Si no hay token o el token ha expirado, redirige al login
  if (!token || (tokenExpireTime && Date.now() >= tokenExpireTime)) {
    localStorage.clear();
    window.location.href = '/login';
    return; // Detiene la ejecución de la función
  }

  // Si el token está a punto de expirar (por ejemplo, en los últimos 15 segundos),
  // puedes implementar una lógica para renovar el token aquí antes de hacer la solicitud.
  // Por simplicidad, aquí simplemente actualizamos el tiempo de expiración del token en 15 segundos.
  const decodedToken = jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
  const tokenExpirationThreshold = 15; // Umbral de expiración del token en segundos

  if (decodedToken.exp - currentTime <= tokenExpirationThreshold) {
    tokenExpireTime = decodedToken.exp * 1000; // Convertir el tiempo de expiración del token de segundos a milisegundos
    localStorage.setItem('tokenExpireTime', tokenExpireTime); // Actualiza el tiempo de expiración del token en localStorage
  }

  const headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  };

  const response = await fetch(`${BASE_URL}${endpoint}`, { ...options, headers });

  if (response.status === 401) {
    // Manejar el error 401
    localStorage.clear();
    window.location.href = '/login'; // Redirigir al login
  } else if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};



*/