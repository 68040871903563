import React from 'react';
import { Calendar } from 'react-big-calendar';

export default function CalendarComponent({ localizer, onSelectSlot, dayPropGetter, selectedDate }) {
  const handleNavigate = (newDate, view, action) => {
    try {
      if (action === 'TODAY') {
        onSelectSlot({ start: newDate, end: newDate });
      }
    } catch (error) {
      console.error('Error occurred while handling navigation:', error);
    }
  };

  return (
    <div className="w-4/5 mx-auto bg-white p-4 rounded-lg shadow-lg">
      <Calendar
        localizer={localizer}
        style={{ height: 'auto', minHeight: '500px' }}
        selectable
        onSelectSlot={onSelectSlot}
        dayPropGetter={dayPropGetter}
        defaultDate={selectedDate}
        views={['month']}
        messages={{
          today: 'Hoy',
          previous: 'Anterior',
          next: 'Siguiente',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
          agenda: 'Agenda',
          date: 'Fecha',
          time: 'Hora',
          event: 'Evento',
        }}
        onNavigate={handleNavigate}
        className="rounded-lg"
      />
    </div>
  );
}
