import React from 'react';
import { Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { fetchWithAuth } from '../utils/apiHelper';

const OperacionesList = ({ data, totales, fetchData }) => {
  const handleDelete = async (id) => {
    try {
      await fetchWithAuth(`/operaciones/operaciones/${id}`, {
        method: 'DELETE',
      });
      toast.success('Registro eliminado exitosamente.');
      fetchData('operations');
    } catch (error) {
      console.error('Error eliminando el registro:', error);
      toast.error('Error al eliminar el registro.');
    }
  };

  return (
    <Transition
      show={data.length > 0}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-white p-4 md:p-10 rounded-lg shadow-lg">
        <h3 className="text-xl md:text-3xl font-bold mb-4 md:mb-6 text-green-800">Operaciones</h3>
        <div className="overflow-x-auto mb-4 md:mb-8">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Fecha</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Hora</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Tipo</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Descripción</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Costo</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Empleado</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Usuario</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Retirado</th>
                <th className="px-3 md:px-6 py-2 md:py-3 border-b border-gray-200 text-left text-xs md:text-sm font-medium text-gray-700">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.operacion_id} className="hover:bg-gray-100 transition-colors">
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">{new Date(item.fecha).toLocaleDateString()}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">{item.hora ? item.hora.slice(0, 5) : ''}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200">{item.tipo}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200">{item.comentario}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">${item.costo.toLocaleString()}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">{item.empleado_nombre}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">{item.usuario_operacion}</td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200 font-semibold">
                    {item.tipo === 'Retiro de efectivo' ? (
                      item.retirado ? 'Retirado' : 'No retirado'
                    ) : null}
                  </td>
                  <td className="px-3 md:px-6 py-2 md:py-4 border-b border-gray-200">
                    <button
                      onClick={() => handleDelete(item.operacion_id)}
                      className="px-3 md:px-4 py-1 md:py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {Object.keys(totales).length > 0 && (
          <div className="bg-green-50 p-4 md:p-6 rounded-lg shadow-lg">
            <h3 className="text-lg md:text-2xl font-bold mb-2 md:mb-4 text-green-800">Resumen de Totales</h3>
            <ul className="space-y-2 md:space-y-3">
              {Object.keys(totales).map((tipo) => (
                <li key={tipo} className="flex justify-between items-center">
                  <span className="text-base md:text-xl font-semibold text-gray-700">{tipo}:</span>
                  <span className="text-base md:text-xl font-semibold text-gray-900">${totales[tipo].toLocaleString()}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Transition>
  );
};

export default OperacionesList;
