import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper';

const RetirosDinero = () => {
  const [retiros, setRetiros] = useState([]);
  const [selectedRetiros, setSelectedRetiros] = useState([]);
  const [totalMonto, setTotalMonto] = useState(0);

  useEffect(() => {
    fetchRetirosPendientes();
  }, []);

  const fetchRetirosPendientes = async () => {
    try {
      const response = await fetchWithAuth('/caja/retiros-pendientes');
      setRetiros(response);
      setTotalMonto(response.reduce((sum, retiro) => sum + retiro.monto, 0));
    } catch (error) {
      console.error('Error al obtener los retiros pendientes:', error);
    }
  };

  const toggleRetiroSeleccionado = (retiroId) => {
    setSelectedRetiros((prevSelected) => {
      if (prevSelected.includes(retiroId)) {
        return prevSelected.filter((id) => id !== retiroId);
      } else {
        return [...prevSelected, retiroId];
      }
    });
  };

  const actualizarRetiros = async (retirado) => {
    try {
      await fetchWithAuth('/caja/retiros/recogido', {
        method: 'PUT',
        body: JSON.stringify({
          retiro_ids: selectedRetiros,
          retirado: retirado
        })
      });
      fetchRetirosPendientes();
      setSelectedRetiros([]);
    } catch (error) {
      console.error('Error al actualizar los retiros:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <main className="flex-grow container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold text-center mb-6">Retiros de Dinero Pendientes</h2>
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={fetchRetirosPendientes}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none"
            >
              Recargar
            </button>
            <span className="text-lg font-semibold">Total en Caja: ${totalMonto}</span>
          </div>
          <ul className="divide-y divide-gray-200">
            {retiros.map((retiro) => (
              <li key={retiro.retiro_id} className="py-4 flex items-center justify-between">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600"
                    checked={selectedRetiros.includes(retiro.retiro_id)}
                    onChange={() => toggleRetiroSeleccionado(retiro.retiro_id)}
                  />
                  <span className="ml-3 text-gray-700">
                    ${retiro.monto} - {new Date(retiro.fecha).toLocaleDateString()}
                  </span>
                </label>
              </li>
            ))}
          </ul>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={() => actualizarRetiros(true)}
              className="px-4 py-2 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 focus:outline-none"
            >
              Marcar como Recogido
            </button>
            <button
              onClick={() => actualizarRetiros(false)}
              className="px-4 py-2 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 focus:outline-none"
            >
              Marcar como No Recogido
            </button>
          </div>
        </div>
      </main>

    </div>
  );
};

export default RetirosDinero;
