import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithAuth } from '../utils/apiHelper';

function BookingModal({ isOpen, data, closeModal, updateAppointments }) {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedService, setSelectedService] = useState('');
  const [appointmentDuration, setAppointmentDuration] = useState('');
  const [comment, setComment] = useState(''); // New state for comment
  const [showCreateClientForm, setShowCreateClientForm] = useState(false);
  const [newClientData, setNewClientData] = useState({ nombre: '', apellido: '', email: '', telefono: '' });
  const [loading, setLoading] = useState(false); // Loading state for the confirm button
  const [clientLoading, setClientLoading] = useState(false); // Loading state for the create client button

  useEffect(() => {
    if (isOpen) {
      fetchClientsAndServices();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      resetModal();
    }
  }, [isOpen]);

  const fetchClientsAndServices = async () => {
    try {
      const clientsData = await fetchWithAuth('/clientes');
      setClients(Array.isArray(clientsData) ? clientsData : []);
      setFilteredClients(Array.isArray(clientsData) ? clientsData : []);

      const servicesData = await fetchWithAuth('/services');
      setServices(Array.isArray(servicesData) ? servicesData : []);
      
    } catch (error) {
      console.error('Failed to fetch clients or services:', error);
    }
  };

  const handleClientSearch = (searchText) => {
    const filtered = clients.filter(client =>
      client.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
      client.apellido.toLowerCase().includes(searchText.toLowerCase()) ||
      (client.email && client.email.toLowerCase().includes(searchText.toLowerCase()))
    );
    setFilteredClients(filtered);
  };

  const handleClientSelect = (clientId) => {
    setSelectedClient(clients.find(client => client.cliente_id === clientId));
  };

  const handleCreateClient = async (e) => {
    e.preventDefault();
    setClientLoading(true);
    const newClient = { ...newClientData, telefono_sufijo: '549' };
    try {
      await fetchWithAuth('/clientes/create', {
        method: 'POST',
        body: JSON.stringify(newClient)
      });
      fetchClientsAndServices(); // Refetch clients to include the new one
      setShowCreateClientForm(false);
      resetNewClientForm();
    } catch (error) {
      console.error('Error al añadir el cliente:', error);
    } finally {
      setClientLoading(false);
    }
  };

  const resetModal = () => {
    setSelectedClient(null);
    setSelectedService('');
    setAppointmentDuration('');
    setComment(''); // Reset comment
    setShowCreateClientForm(false);
    resetNewClientForm();
  };

  const resetNewClientForm = () => {
    setNewClientData({ nombre: '', apellido: '', email: '', telefono: '' });
  };

  const handleConfirm = async () => {
    setLoading(true); // Set loading to true
    const bookingData = {
      empleado_id: data.employee.empleado_id,
      cliente_id: selectedClient.cliente_id,
      service_id: selectedService,
      fecha: data.date,
      horario: data.time,
      duracion: appointmentDuration,
      comentario: comment // Include comment in booking data
    };

    try {
      const response = await fetchWithAuth('/turnos/reservar/turno', {
        method: 'POST',
        body: JSON.stringify(bookingData)
      });

      if (response.turno) { // Asegúrate de que la respuesta contiene el turno
        toast.success('Turno reservado con éxito!');
        updateAppointments(); // Update the appointments list with the new booking
        closeModal();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error('Error creating booking:', error);
      toast.error('Error al crear la reserva');
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-90 z-50 overflow-y-auto h-full w-full flex items-center justify-center transition-opacity duration-300 ease-in-out">
      <div className="relative mx-auto p-5 border w-full max-w-3xl bg-gray-100 shadow-xl rounded-xl transition-all duration-300 ease-in-out">
        <div className="text-center">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Nuevo Turno</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-lg font-medium text-gray-700">Empleado: {`${data.employee.nombre} ${data.employee.apellido}`}</p>
            <p className="text-lg font-medium text-gray-700">Fecha: {data.date}</p>
            <p className="text-lg font-medium text-gray-700">Hora: {data.time}</p>
            {!selectedClient && !showCreateClientForm ? (
              <>
                <input
                  type="text"
                  placeholder="Buscar cliente"
                  className="mt-4 p-3 w-full border border-gray-300 rounded-lg shadow-sm"
                  onChange={e => handleClientSearch(e.target.value)}
                />
                <div className="mt-4 p-3 w-full border border-gray-300 rounded-lg overflow-y-auto" style={{ maxHeight: '200px' }}>
                  {filteredClients.slice(0, 10).map(client => (
                    <div key={client.cliente_id} onClick={() => handleClientSelect(client.cliente_id)}
                      className={`p-2 cursor-pointer ${selectedClient && selectedClient.cliente_id === client.cliente_id ? 'bg-blue-300' : 'hover:bg-gray-200'}`}>
                      {client.nombre} {client.apellido} - {client.telefono}
                    </div>
                  ))}
                </div>
                <button onClick={closeModal} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full shadow-lg mr-2">
                  Cerrar
                </button>
                
                <button onClick={() => setShowCreateClientForm(true)} className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full shadow-lg">
                  Crear Cliente
                </button>
              </>
            ) : showCreateClientForm ? (
              <form onSubmit={handleCreateClient} className="space-y-4">
                <input type="text" placeholder="Nombre" value={newClientData.nombre} onChange={(e) => setNewClientData({ ...newClientData, nombre: e.target.value })} className="mt-1 p-2 w-full border border-gray-300 rounded-md" required />
                <input type="text" placeholder="Apellido" value={newClientData.apellido} onChange={(e) => setNewClientData({ ...newClientData, apellido: e.target.value })} className="mt-1 p-2 w-full border border-gray-300 rounded-md" required />
                <div className="flex items-center mt-1 w-full">
                  <span className="px-3 py-2 border border-r-0 border-gray-300 bg-gray-100 rounded-l-md text-gray-600">+549</span>
                  <input 
                    type="text" 
                    placeholder="Teléfono" 
                    value={newClientData.telefono} 
                    onChange={(e) => setNewClientData({ ...newClientData, telefono: e.target.value })} 
                    className="p-2 w-full border border-gray-300 rounded-r-md" 
                    required 
                  />
                </div>
                <button type="submit" className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-lg ${clientLoading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={clientLoading}>
                  {clientLoading ? 'Añadiendo...' : 'Añadir Cliente'}
                </button>
                <button onClick={() => setShowCreateClientForm(false)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full shadow-lg">
                  Cancelar
                </button>
              </form>
            ) : (
              <div className="mt-4 bg-blue-100 rounded-lg p-3 shadow">
                Cliente seleccionado: <span className="font-medium">{selectedClient.nombre} {selectedClient.apellido}</span>
                <button onClick={() => setSelectedClient(null)} className="ml-2 text-blue-500 hover:text-blue-700">Deseleccionar</button>
              </div>
            )}
            {selectedClient && (
              <>
                <select value={selectedService} onChange={e => setSelectedService(e.target.value)} className="mt-4 p-3 w-full border border-gray-300 rounded-lg shadow-sm">
                  <option value="">Seleccionar servicio</option>
                  {services.map(service => (
                    <option key={service.servicio_id} value={service.servicio_id}>
                      {service.nombre} - ${service.precio}
                    </option>
                  ))}
                </select>
                <div className="flex justify-around my-4">
                  {['30min', '1hr', '1:30', '2hs'].map(duration => (
                    <label key={duration} className="flex items-center space-x-2">
                      <input type="radio" name="duration" value={duration} checked={appointmentDuration === duration} onChange={() => setAppointmentDuration(duration)} />
                      <span>{duration}</span>
                    </label>
                  ))}
                </div>
                <textarea
                  className="mt-4 p-3 w-full border border-gray-300 rounded-lg shadow-sm"
                  placeholder="Comentario (opcional)"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <button onClick={closeModal} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full shadow-lg mr-2">
                  Cerrar
                </button>
                <button
                  onClick={handleConfirm}
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-lg ${!selectedService || !appointmentDuration || loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={!selectedService || !appointmentDuration || loading}
                >
                  {loading ? 'Confirmando...' : 'Confirmar'}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default BookingModal;
