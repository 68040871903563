import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchWithAuth } from '../utils/apiHelper';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { FaUserCircle, FaCalendarAlt, FaMoneyBillWave, FaExclamationTriangle } from 'react-icons/fa';

const ClienteDetalles = () => {
  const { id } = useParams();
  const [clientDetails, setClientDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredTurnos, setFilteredTurnos] = useState(null);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const data = await fetchWithAuth(`/clientes/id/${id}/`);
        setClientDetails(data);
        setFilteredTurnos(data);
      } catch (error) {
        toast.error('Error al obtener los detalles del cliente');
      } finally {
        setLoading(false);
      }
    };

    fetchClientDetails();
  }, [id]);

  useEffect(() => {
    if (clientDetails && startDate && endDate) {
      const filtered = {
        pagados: filterTurnosByDate(clientDetails.pagados),
        confirmados: filterTurnosByDate(clientDetails.confirmados),
        reservados: filterTurnosByDate(clientDetails.reservados),
        cancelados: filterTurnosByDate(clientDetails.cancelados),
      };
      setFilteredTurnos(filtered);
    } else {
      setFilteredTurnos(clientDetails);
    }
  }, [startDate, endDate, clientDetails]);

  const filterTurnosByDate = (turnos) => {
    return turnos.filter(turno => {
      const turnoDate = moment(turno.fecha).startOf('day');
      return turnoDate.isSameOrAfter(moment(startDate).startOf('day')) && turnoDate.isSameOrBefore(moment(endDate).startOf('day'));
    });
  };

  const formatDate = (dateString, timeString) => {
    const dateTimeString = `${dateString.split('T')[0]}T${timeString}`;
    return moment(dateTimeString).format('DD MMMM YYYY, h:mm A');
  };

  if (loading) {
    return <div className="text-center py-10">Cargando...</div>;
  }

  if (!clientDetails) {
    return <div className="text-center py-10">No se encontraron detalles para este cliente.</div>;
  }

  const renderTurnos = (turnos, colorClass, title, icon) => (
    <div className={`mb-6 p-4 rounded-lg shadow-md ${colorClass}`}>
      <div className="flex items-center mb-4">
        <div className="text-2xl mr-2">{icon}</div>
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      {turnos.length > 0 ? (
        turnos.map((turno) => (
          <div key={turno.turno_id} className="p-4 bg-white rounded-md shadow-sm mb-4 transition-transform transform hover:scale-105 hover:shadow-lg">
            <p className="text-gray-600"><FaCalendarAlt className="inline-block mr-2" /> <strong>Fecha:</strong> {formatDate(turno.fecha, turno.hora)}</p>
            <p className="text-gray-600"><FaUserCircle className="inline-block mr-2" /> <strong>Empleado:</strong> {turno.empleado}</p>
            <p className="text-gray-600"><FaCalendarAlt className="inline-block mr-2" /> <strong>Servicio:</strong> {turno.servicio.nombre}</p>
            {turno.pago && (
              <p className="text-gray-600"><FaMoneyBillWave className="inline-block mr-2" /> <strong>Pago:</strong> ${turno.pago.monto} - {turno.pago.metodo} ({formatDate(turno.pago.fecha, turno.pago.hora)})</p>
            )}
            {turno.cancelacion && (
              <>
                <p className="text-gray-600"><FaExclamationTriangle className="inline-block mr-2" /> <strong>Motivo de Cancelación:</strong> {turno.cancelacion.motivo}</p>
                <p className="text-gray-600"><FaCalendarAlt className="inline-block mr-2" /> <strong>Fecha de Cancelación:</strong> {formatDate(turno.cancelacion.fecha, turno.cancelacion.hora)}</p>
              </>
            )}
            <p className="text-gray-600"><strong>Comentario:</strong> {turno.comentario || 'N/A'}</p>
          </div>
        ))
      ) : (
        <p className="text-gray-600">No hay turnos en esta categoría.</p>
      )}
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-4xl font-bold mb-6 text-center text-gray-800">Detalles del Cliente</h2>
      <div className="text-center mb-8">
        <h3 className="text-2xl font-semibold text-gray-700">Cliente: {clientDetails.nombre_cliente}</h3>
      </div>
      <div className="mb-6 flex items-center justify-center">
        <label className="mr-4 font-semibold text-gray-700">Filtrar por Fecha:</label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="border p-2 rounded-md mr-2 text-gray-700"
          placeholderText="Fecha de inicio"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="border p-2 rounded-md text-gray-700"
          placeholderText="Fecha de fin"
        />
        <button
          className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
            setFilteredTurnos(clientDetails);
          }}
        >
          Limpiar Filtros
        </button>
      </div>

      {renderTurnos(filteredTurnos.pagados, 'bg-yellow-100 border-l-4 border-yellow-500', 'Turnos Pagados', <FaMoneyBillWave />)}
      {renderTurnos(filteredTurnos.confirmados, 'bg-green-100 border-l-4 border-green-500', 'Turnos Confirmados', <FaCalendarAlt />)}
      {renderTurnos(filteredTurnos.reservados, 'bg-blue-100 border-l-4 border-blue-500', 'Turnos Reservados', <FaCalendarAlt />)}
      {renderTurnos(filteredTurnos.cancelados, 'bg-red-100 border-l-4 border-red-500', 'Turnos Cancelados', <FaExclamationTriangle />)}
    </div>
  );
};

export default ClienteDetalles;
