import { useState, useCallback } from 'react';
import moment from 'moment';
import { fetchWithAuth } from '../utils/apiHelper'; // Importa la función fetchWithAuth

export default function useSchedule(selectedDate) {
  const [employees, setEmployees] = useState([]);
  const [sucursalData, setSucursalData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchEmployeesAndSchedules = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchWithAuth('/horarios/empleados');
      setEmployees(data);
      console.log(data)
    } catch (error) {
      console.error('Failed to fetch employees and schedules:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchHorariosLocal = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchWithAuth('/horarios/sucursal/horas');
      setSucursalData(data);
    } catch (error) {
      console.error('Failed to fetch employees and schedules:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAppointments = useCallback(async (date) => {
    setLoading(true);
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const data = await fetchWithAuth(`/turnos/turno/${formattedDate}`);
      setAppointments(data);
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedDate]);

  const clearAppointments = useCallback(() => {
    setAppointments([]);
  }, []);


  return {
    employees,
    appointments,
    loading,
    sucursalData,
    fetchHorariosLocal,
    fetchEmployeesAndSchedules,
    fetchAppointments,
    clearAppointments, 
  };
}
